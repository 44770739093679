@use 'abstracts/variables';
@use 'sass:meta';
@use 'normalize.css';

*,
*:after,
*:before {
  box-sizing: border-box;
}

li {
  list-style: none;
}

ul {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  color: variables.$basic-text-link;
  text-decoration: none;
  opacity: 1;
  transition: all 150ms variables.$standard-easing;
}

html {
  font-size: variables.$base-font-size;
  text-rendering: optimizeLegibility;
  // The rules below will be useful after the new fonts addition
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: variables.$base-font, variables.$fallback-font;
  font-size: 1em;
  line-height: variables.$base-line-height;
  font-weight: variables.$base-font-weight;
  color: variables.$basic-text;
  background-color: variables.$basic-background;
}

strong {
  font-weight: 600;
}

figure {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
}
